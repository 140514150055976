
import { Component, Vue } from 'vue-property-decorator'
import { apiBatchEdit, apiIntegralGoodsDel, apiIntegralGoodsLists, apiIntegralGoodsOtherList, apiIntegralGoodsStatus } from '@/api/application/integral_mall'
import { RequestPaging } from '@/utils/util'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
@Component({
  components: {
    LsDialog,
    LsPagination
  }
})
export default class IntegralGoods extends Vue {
    formData = {
      name: '',
      status: '',
      category_id: '',
      discount: ''
    }

  categoryList: any[] = []

  discountList:any[] = []

  selectIds: any[] = []
  // 批量修改字段和值
  fieldValue:any = ''

  get disabledBtn () {
    return !this.selectIds.length
  }

    pager = new RequestPaging()
    getList (page?: number): void {
      page && (this.pager.page = page)
      this.pager.request({
        callback: apiIntegralGoodsLists,
        params: this.formData
      })
    }

    handleSelect (val: any[]) {
      this.selectIds = val.map(item => item.id)
    }

    handleReset () {
      Object.keys(this.formData).forEach(key => {
        this.$set(this.formData, key, '')
      })
      this.getList()
    }

    handleStatus (value: number, id: number) {
      apiIntegralGoodsStatus({
        id,
        status: value
      }).then(() => {
        this.getList()
      })
    }

    getOtherList () {
      apiIntegralGoodsOtherList({
        type: 'list'
      }).then((res: any) => {
        this.categoryList = res?.category_list
        this.discountList = res?.discount
      })
    }

    handleDelete (id: number) {
      apiIntegralGoodsDel({ id }).then(() => {
        this.getList()
      })
    }

    // 批量修改价格
    async handleBatchEvent (ids: any, field: string) {
      const data = await apiBatchEdit({
        ids: ids || this.selectIds,
        value: this.fieldValue,
        field
      })
      this.getList()
    }

    created () {
      this.getOtherList()
      this.getList()
    }

    activated () {
      this.getOtherList()
      this.getList()
    }
}
